/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useConnectQueryVariables = {};
export type useConnectQueryResponse = {
    readonly me: {
        readonly id: string;
        readonly type: string;
        readonly username: string;
        readonly email: string | null;
        readonly emailVerified: boolean | null;
        readonly name: string | null;
        readonly avatarUrl: string | null;
        readonly timeZone: string | null;
        readonly locale: string | null;
        readonly createdAt: string | null;
        readonly updatedAt: string | null;
        readonly lastLogin: string | null;
    } | null;
    readonly installations: ReadonlyArray<{
        readonly id: string;
        readonly account: {
            readonly id: string;
            readonly username: string;
            readonly avatarUrl: string | null;
        };
    }> | null;
};
export type useConnectQuery = {
    readonly response: useConnectQueryResponse;
    readonly variables: useConnectQueryVariables;
};



/*
query useConnectQuery {
  me {
    id
    type
    username
    email
    emailVerified
    name
    avatarUrl
    timeZone
    locale
    createdAt
    updatedAt
    lastLogin
  }
  installations {
    id
    account {
      id
      username
      avatarUrl
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "emailVerified",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timeZone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locale",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastLogin",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Installation",
    "kind": "LinkedField",
    "name": "installations",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "account",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useConnectQuery",
    "selections": (v3/*: any*/),
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useConnectQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "db92d1cf60fec3471b91ea7b78199600",
    "id": null,
    "metadata": {},
    "name": "useConnectQuery",
    "operationKind": "query",
    "text": "query useConnectQuery {\n  me {\n    id\n    type\n    username\n    email\n    emailVerified\n    name\n    avatarUrl\n    timeZone\n    locale\n    createdAt\n    updatedAt\n    lastLogin\n  }\n  installations {\n    id\n    account {\n      id\n      username\n      avatarUrl\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cfaba2cac142b81b18f44a3ab587fd59';
export default node;
