import {
  Drawer,
  DrawerProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Toolbar,
} from "@material-ui/core";
import { Explore, Label, Star } from "@material-ui/icons";
import * as React from "react";
import { useCurrentUser, useLocation, useNavigate } from "../hooks";

const menuItems = [
  { label: "Explore", href: "/", Icon: Explore },
  { label: "Favorites", href: "/favorites", Icon: Star },
];

type AppDrawerProps = Omit<DrawerProps, "children">;

export function AppDrawer(props: AppDrawerProps): JSX.Element {
  const { sx, ...other } = props;

  const { pathname: path } = useLocation();
  const user = useCurrentUser();
  const navigate = useNavigate();

  return (
    <Drawer
      sx={{
        width: 260,
        ".MuiPaper-root": { width: 260 },
        ...sx,
      }}
      open={true}
      variant="persistent"
      {...other}
    >
      <Toolbar />
      <List>
        <ListSubheader>Templates</ListSubheader>

        {menuItems.map((x) => (
          <ListItem
            key={x.label}
            href={x.href}
            onClick={navigate}
            selected={x.href === path}
            button
          >
            <ListItemIcon sx={{ minWidth: "40px" }} children={<x.Icon />} />
            <ListItemText
              sx={{ my: 0, ".MuiTypography-root": { fontSize: "0.825rem" } }}
              primary={x.label}
            />
          </ListItem>
        ))}

        {(user?.projects?.length ?? 0) > 0 && (
          <ListSubheader>Projects</ListSubheader>
        )}

        {user?.projects?.map((x) => (
          <ListItem
            key={x.id}
            href={`/projects/${rawId(x.id)}`}
            onClick={navigate}
            button
          >
            <ListItemIcon sx={{ minWidth: "40px" }} children={<Label />} />
            <ListItemText
              sx={{ my: 0, ".MuiTypography-root": { fontSize: "0.825rem" } }}
              primary={x.name}
            />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}

function rawId(globalId: string): string {
  return atob(globalId).split(":")[1];
}
