import * as React from "react";
import { graphql, useRelayEnvironment } from "react-relay";
import { createOperationDescriptor, getRequest } from "relay-runtime";

const meQuery = graphql`
  query useConnectQuery {
    me {
      id
      type
      username
      email
      emailVerified
      name
      avatarUrl
      timeZone
      locale
      createdAt
      updatedAt
      lastLogin
    }
    installations {
      id
      account {
        id
        username
        avatarUrl
      }
    }
  }
`;

// Pop-up window for Google/Facebook authentication
let loginWindow: WindowProxy | null = null;

export function useConnect(
  type: "login" | "install"
): (
  event?: React.MouseEvent | ((err?: Error, installationId?: string) => void)
) => void {
  const callbackRef = React.useRef<
    ((err?: Error, installationId?: string) => void) | undefined
  >();
  const relay = useRelayEnvironment();

  // Start listening for notifications from the pop-up login window
  React.useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (
        event.origin === window.location.origin &&
        event.source === loginWindow
      ) {
        if (event.data.error) {
          callbackRef.current?.(new Error(event.data.error));
        } else if (event.data.data) {
          const request = getRequest(meQuery);
          const operation = createOperationDescriptor(request, {});
          relay.commitPayload(operation, event.data.data);
          callbackRef.current?.(undefined, event.data.installationId);
        }
      }
    }

    window.addEventListener("message", handleMessage, false);

    return () => {
      callbackRef.current = undefined;
      window.removeEventListener("message", handleMessage);
    };
  }, [type]);

  return React.useCallback(
    function (event?: React.MouseEvent | (() => void)) {
      if (typeof event === "function") {
        callbackRef.current = event;
      } else {
        event?.preventDefault();
      }
      // TODO: Pass application name for PROD vs TEST
      // const url = "https://github.com/apps/getboilerplate-com/installations/new";
      const url =
        type === "login"
          ? "/auth/github"
          : window.location.hostname === "getboilerplate.com"
          ? "https://github.com/apps/getboilerplate-com/installations/new"
          : "https://github.com/apps/getboilerplate/installations/new";

      if (loginWindow === null || loginWindow.closed) {
        const width = type === "login" ? 375 : 1024;
        const height = type === "login" ? 555 : 800;
        const left = window.top.outerWidth / 2 + window.top.screenX - width / 2;
        const top =
          window.top.outerHeight / 2 + window.top.screenY - height / 2;
        loginWindow = window.open(
          url,
          "login",
          `menubar=no,toolbar=no,status=no,width=${width},height=${height},left=${left},top=${top}`
        );
      } else {
        loginWindow.focus();
        loginWindow.location.href = url;
      }
    },
    [type]
  );
}
