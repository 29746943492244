/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type projectQueryVariables = {
    slug: string;
};
export type projectQueryResponse = {
    readonly template: {
        readonly name: string;
        readonly " $fragmentRefs": FragmentRefs<"Create_template">;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"Create_data">;
};
export type projectQuery = {
    readonly response: projectQueryResponse;
    readonly variables: projectQueryVariables;
};



/*
query projectQuery(
  $slug: String!
) {
  ...Create_data
  template(slug: $slug) {
    ...Create_template
    name
    id
  }
}

fragment Create_data on Root {
  me {
    id
    type
    username
    email
    name
    avatarUrl
  }
  installations {
    id
    account {
      id
      username
      avatarUrl
    }
  }
}

fragment Create_template on Repository {
  id
  name
  fullName
  description
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "projectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Repository",
        "kind": "LinkedField",
        "name": "template",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Create_template"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Create_data"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "projectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          (v2/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Installation",
        "kind": "LinkedField",
        "name": "installations",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Repository",
        "kind": "LinkedField",
        "name": "template",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "80fa6c5e0cfef9eb0aa1fc4c30f470e5",
    "id": null,
    "metadata": {},
    "name": "projectQuery",
    "operationKind": "query",
    "text": "query projectQuery(\n  $slug: String!\n) {\n  ...Create_data\n  template(slug: $slug) {\n    ...Create_template\n    name\n    id\n  }\n}\n\nfragment Create_data on Root {\n  me {\n    id\n    type\n    username\n    email\n    name\n    avatarUrl\n  }\n  installations {\n    id\n    account {\n      id\n      username\n      avatarUrl\n    }\n  }\n}\n\nfragment Create_template on Repository {\n  id\n  name\n  fullName\n  description\n}\n"
  }
};
})();
(node as any).hash = '33b73da5dc2587945d6fcdb91df3a5b1';
export default node;
