/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import * as React from "react";
import {
  fetchQuery,
  graphql,
  useFragment,
  useRelayEnvironment,
} from "react-relay";
import { createOperationDescriptor, getRequest } from "relay-runtime";
import type { useCurrentUserQuery as Query } from "./__generated__/useCurrentUserQuery.graphql";
import type {
  useCurrentUser_me,
  useCurrentUser_me$key,
} from "./__generated__/useCurrentUser_me.graphql";

export type User = useCurrentUser_me | null;

const query = graphql`
  query useCurrentUserQuery {
    me {
      ...useCurrentUser_me
    }
  }
`;
const variables = {};
const fragment = graphql`
  fragment useCurrentUser_me on User {
    id
    type
    username
    email
    name
    avatarUrl
    projects {
      id
      name
    }
  }
`;

const operation = createOperationDescriptor(getRequest(query), variables);
let promise: Promise<Query["response"]>;

/**
 * Returns the currently logged in user object (`me`), `null` for anonymous
 * users, and `undefined` when the user status has not been resolved yet.
 */
export function useCurrentUser(forceFetch = false): User | null | undefined {
  // Attempt to read the current user record (me) from the local store.
  const relay = useRelayEnvironment();
  const { data, isMissingData } = relay.lookup(operation.fragment);
  const user = useFragment(fragment, data.me as useCurrentUser_me$key);

  // Once the component is mounted, attempt to load user record from the API.
  React.useEffect(() => {
    promise =
      promise ??
      fetchQuery<Query>(relay, query, variables, {
        networkCacheConfig: { force: forceFetch || isMissingData },
        fetchPolicy: "store-or-network",
      }).toPromise();
  }, [relay, forceFetch]);

  return user;
}
