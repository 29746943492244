/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import { graphql } from "relay-runtime";
import type { Route } from "../../core";
import type Create from "./Create";
import type ProjectList from "./ProjectList";
import type { projectListQueryResponse } from "./__generated__/projectListQuery.graphql";
import type { projectQueryResponse } from "./__generated__/projectQuery.graphql";

/**
 * Homepage route.
 */
export default [
  {
    path: "/projects",
    query: graphql`
      query projectListQuery {
        ...ProjectList_projects
      }
    `,
    component: () => import(/* webpackChunkName: "project" */ "./ProjectList"),
    response: (data) => ({
      title: `My Projects • Boilerplate`,
      description: "Web application built with React and Relay",
      props: data,
    }),
  } as Route<typeof ProjectList, projectListQueryResponse>,
  {
    path: "/:owner/:repo",
    variables: ({ params }) => {
      return { slug: `${params.owner}/${params.repo}` };
    },
    query: graphql`
      query projectQuery($slug: String!) {
        ...Create_data
        template(slug: $slug) {
          ...Create_template
          name
        }
      }
    `,
    component: () => import(/* webpackChunkName: "project" */ "./Create"),
    response: (data) => ({
      title: `${data.template?.name} • Boilerplate`,
      description: "Web application built with React and Relay",
      props: data,
    }),
  } as Route<typeof Create, projectQueryResponse>,
];
