/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type projectListQueryVariables = {};
export type projectListQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ProjectList_projects">;
};
export type projectListQuery = {
    readonly response: projectListQueryResponse;
    readonly variables: projectListQueryVariables;
};



/*
query projectListQuery {
  ...ProjectList_projects
}

fragment ProjectCard_project on Project {
  id
  name
  template {
    id
    fullName
  }
  repository {
    id
    fullName
  }
}

fragment ProjectList_projects on Root {
  projects {
    ...ProjectCard_project
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fullName",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "projectListQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ProjectList_projects"
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "projectListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "projects",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Repository",
            "kind": "LinkedField",
            "name": "template",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Repository",
            "kind": "LinkedField",
            "name": "repository",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "03a10cc8d22d3c4bfe0d33f0806cb3b7",
    "id": null,
    "metadata": {},
    "name": "projectListQuery",
    "operationKind": "query",
    "text": "query projectListQuery {\n  ...ProjectList_projects\n}\n\nfragment ProjectCard_project on Project {\n  id\n  name\n  template {\n    id\n    fullName\n  }\n  repository {\n    id\n    fullName\n  }\n}\n\nfragment ProjectList_projects on Root {\n  projects {\n    ...ProjectCard_project\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '2b7bc422db54f7efe03201bdedcf3721';
export default node;
