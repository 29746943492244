/**
 * @copyright 2016-present Kriasoft (https://git.io/Jt7GM)
 */

import { useTheme } from "@material-ui/core/styles";
import * as React from "react";

type LogoProps = React.SVGProps<SVGSVGElement>;

export const Logo = React.forwardRef<SVGSVGElement>(function Logo(
  props: LogoProps,
  ref
): JSX.Element {
  const { width, height, ...other } = props;
  const theme = useTheme();

  const originalWidth = 731.01;
  const originalHeight = 123.65;

  return (
    <svg
      ref={ref as React.LegacyRef<SVGSVGElement>}
      width={width ?? (Number(height) * originalWidth) / originalHeight}
      height={height ?? (Number(width) * originalWidth) / originalHeight}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${originalWidth} ${originalHeight}`}
      {...other}
    >
      <title>Boilerplate Logo</title>
      <path
        d="M46.8,50.48a5.54,5.54,0,0,0-1.76-1,6.9,6.9,0,0,0-2.35-.37,6.76,6.76,0,0,0-2.32.37,5.6,5.6,0,0,0-1.77,1,4.21,4.21,0,0,0-1.12,5,4.5,4.5,0,0,0,1.12,1.46,5.42,5.42,0,0,0,1.77,1,7,7,0,0,0,2.32.36A7.16,7.16,0,0,0,45,57.93a5.37,5.37,0,0,0,1.76-1,4.36,4.36,0,0,0,1.12-1.46,4.17,4.17,0,0,0-1.12-5Z"
        transform="translate(-1.36 -1.46)"
      />
      <path
        d="M87.81,59.67a23.82,23.82,0,0,0-9.25-9.53,22.62,22.62,0,0,0,5.4-15,22.79,22.79,0,0,0-2.1-9.74,20.65,20.65,0,0,0-6.4-7.78,32.65,32.65,0,0,0-10.83-5.15,56.13,56.13,0,0,0-15.31-1.86h-48V100.4H56.21a54.34,54.34,0,0,0,15.37-1.93A29.73,29.73,0,0,0,82.38,93a22.23,22.23,0,0,0,6.37-8.65A29.19,29.19,0,0,0,90.85,73,28.49,28.49,0,0,0,87.81,59.67ZM59.87,78.73A66.11,66.11,0,0,1,56.39,88a1.4,1.4,0,0,1-1.28.92A1.36,1.36,0,0,1,53.79,88c-1.21-2.3-2.42-4.59-3.62-6.89a.53.53,0,0,0-.56-.34q-6.36.12-12.74.21a.5.5,0,0,0-.53.33c-1.16,2.43-2.34,4.85-3.52,7.28a4.37,4.37,0,0,1-.25.48,1.36,1.36,0,0,1-2.31,0C28,84.49,25.9,79.83,25.19,74.69c-.09-.67-.1-1.34-.13-2a8.35,8.35,0,0,1,2.41-6.18,28.15,28.15,0,0,1,4-3.53.5.5,0,0,0,.18-.54,79.67,79.67,0,0,1-.58-10,44.58,44.58,0,0,1,3.55-17.61A41,41,0,0,1,40.7,25c.17-.2.32-.4.5-.59a1.38,1.38,0,0,1,2,0l.55.55A41.91,41.91,0,0,1,49.87,34a42,42,0,0,1,4,14.52,72.28,72.28,0,0,1,0,12.74.86.86,0,0,0,.43.9,27,27,0,0,1,4.14,3.49,8.59,8.59,0,0,1,2.5,6.3A25.77,25.77,0,0,1,59.87,78.73Z"
        transform="translate(-1.36 -1.46)"
      />
      <path
        d="M119.77,96.69a32.52,32.52,0,0,1-13-12.48A35.18,35.18,0,0,1,102.1,66.1a34.86,34.86,0,0,1,4.68-18,32.75,32.75,0,0,1,13-12.41,41.48,41.48,0,0,1,37.5,0,32.75,32.75,0,0,1,13,12.41,34.86,34.86,0,0,1,4.67,18,35.18,35.18,0,0,1-4.67,18.11,32.52,32.52,0,0,1-13,12.48,41.48,41.48,0,0,1-37.5,0Zm33.28-15.1q5.76-5.88,5.76-15.49t-5.76-15.49a19.58,19.58,0,0,0-14.6-5.88,19.34,19.34,0,0,0-14.52,5.88q-5.7,5.89-5.7,15.49t5.7,15.49a19.34,19.34,0,0,0,14.52,5.89A19.59,19.59,0,0,0,153.05,81.59Z"
        transform="translate(-1.36 -1.46)"
      />
      <path
        d="M191.06,17.91a9,9,0,0,1-2.94-6.85,9,9,0,0,1,2.94-6.85,10.3,10.3,0,0,1,7.3-2.75,10.55,10.55,0,0,1,7.29,2.63,8.43,8.43,0,0,1,2.95,6.59,9.65,9.65,0,0,1-2.88,7.1,10,10,0,0,1-7.36,2.88A10.3,10.3,0,0,1,191.06,17.91Zm-.77,14h16v68.35h-16Z"
        transform="translate(-1.36 -1.46)"
      />
      <path
        d="M233.24,95.35q-6-5.82-6-16.45V5.3h16V77.75q0,10.25,9.6,10.24a12.71,12.71,0,0,0,5.76-1.28l.77,12.8a27.36,27.36,0,0,1-9.6,1.66Q239.19,101.17,233.24,95.35Z"
        transform="translate(-1.36 -1.46)"
      />
      <path
        d="M320.08,80.18,328.53,90A27.83,27.83,0,0,1,317,98.36a46.19,46.19,0,0,1-35.71-1.67,32.2,32.2,0,0,1-13.25-12.48,35.28,35.28,0,0,1-4.67-18.11,35.75,35.75,0,0,1,4.54-18,32.31,32.31,0,0,1,12.55-12.48,36.38,36.38,0,0,1,18.11-4.48,36.85,36.85,0,0,1,17.47,4.16,31.25,31.25,0,0,1,12.48,11.9,34.79,34.79,0,0,1,4.61,18.11L280.79,75.57a17.78,17.78,0,0,0,7.81,9,25.6,25.6,0,0,0,12.92,3.08A25.16,25.16,0,0,0,320.08,80.18ZM284.31,49.72q-5.44,5.76-5.44,15.49v.12l38.65-7.42A18.2,18.2,0,0,0,310.8,47.8,19.61,19.61,0,0,0,298.58,44Q289.75,44,284.31,49.72Z"
        transform="translate(-1.36 -1.46)"
      />
      <path
        d="M388.05,31.16V46.39a20,20,0,0,0-3.71-.38q-9.34,0-14.59,5.44T364.5,67.13v33.15h-16V31.93h15.23v10Q370.65,31.16,388.05,31.16Z"
        transform="translate(-1.36 -1.46)"
      />
      <path
        d="M457.36,35.51A31.41,31.41,0,0,1,469.72,47.8a36.65,36.65,0,0,1,4.48,18.3,37,37,0,0,1-4.48,18.37,31.31,31.31,0,0,1-12.36,12.35,36.27,36.27,0,0,1-17.85,4.35q-13.83,0-21.89-9.21v33.15h-16V31.93h15.23v9a24.73,24.73,0,0,1,9.79-7.3,33.12,33.12,0,0,1,12.87-2.43A36.27,36.27,0,0,1,457.36,35.51Zm-5.12,46.08q5.7-5.88,5.7-15.49t-5.7-15.49a19.34,19.34,0,0,0-14.52-5.88,20.57,20.57,0,0,0-10.37,2.62,18.93,18.93,0,0,0-7.3,7.49,22.93,22.93,0,0,0-2.69,11.26,22.9,22.9,0,0,0,2.69,11.26,18.87,18.87,0,0,0,7.3,7.49,20.58,20.58,0,0,0,10.37,2.63A19.34,19.34,0,0,0,452.24,81.59Z"
        transform="translate(-1.36 -1.46)"
      />
      <path
        d="M495.38,95.35q-6-5.82-5.95-16.45V5.3h16V77.75Q505.43,88,515,88a12.77,12.77,0,0,0,5.76-1.28l.76,12.8a27.28,27.28,0,0,1-9.6,1.66Q501.33,101.17,495.38,95.35Z"
        transform="translate(-1.36 -1.46)"
      />
      <path
        d="M598,31.93v68.35H582.74V91.45A24.8,24.8,0,0,1,573,98.74a33.16,33.16,0,0,1-12.87,2.43,36.27,36.27,0,0,1-17.85-4.35,30.92,30.92,0,0,1-12.29-12.29,37.2,37.2,0,0,1-4.42-18.43,36.89,36.89,0,0,1,4.42-18.37,31.07,31.07,0,0,1,12.29-12.22,36.27,36.27,0,0,1,17.85-4.35,31.83,31.83,0,0,1,12.23,2.3A27,27,0,0,1,582,40.24V31.93Zm-21.5,49.66q5.76-5.88,5.76-15.49t-5.76-15.49a19.58,19.58,0,0,0-14.6-5.88,19.34,19.34,0,0,0-14.52,5.88q-5.7,5.89-5.7,15.49t5.7,15.49a19.34,19.34,0,0,0,14.52,5.89A19.59,19.59,0,0,0,576.47,81.59Z"
        transform="translate(-1.36 -1.46)"
      />
      <path
        d="M657.36,96.57a19,19,0,0,1-6.85,3.45,30.69,30.69,0,0,1-8.51,1.15q-11.26,0-17.41-5.89t-6.14-17.15V16.82h16V32.44h18.3v12.8h-18.3V77.75q0,5,2.5,7.61a9.14,9.14,0,0,0,7,2.63,14,14,0,0,0,9-2.82Z"
        transform="translate(-1.36 -1.46)"
      />
      <path
        d="M719.31,80.18,727.76,90a28,28,0,0,1-11.52,8.32,46.19,46.19,0,0,1-35.71-1.67,32.2,32.2,0,0,1-13.25-12.48,35.28,35.28,0,0,1-4.67-18.11,35.75,35.75,0,0,1,4.54-18A32.31,32.31,0,0,1,679.7,35.64a36.38,36.38,0,0,1,18.11-4.48,36.85,36.85,0,0,1,17.47,4.16,31.25,31.25,0,0,1,12.48,11.9,34.79,34.79,0,0,1,4.61,18.11L680,75.57a17.78,17.78,0,0,0,7.81,9,25.6,25.6,0,0,0,12.92,3.08A25.18,25.18,0,0,0,719.31,80.18ZM683.54,49.72q-5.44,5.76-5.44,15.49v.12l38.65-7.42A18.2,18.2,0,0,0,710,47.8,19.61,19.61,0,0,0,697.81,44Q689,44,683.54,49.72Z"
        transform="translate(-1.36 -1.46)"
      />
    </svg>
  );
});
