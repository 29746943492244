/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type useCurrentUserQueryVariables = {};
export type useCurrentUserQueryResponse = {
    readonly me: {
        readonly id: string;
        readonly type: string;
        readonly username: string;
        readonly email: string | null;
        readonly name: string | null;
        readonly avatarUrl: string | null;
        readonly projects: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
        }> | null;
        readonly " $fragmentRefs": FragmentRefs<"useCurrentUser_me">;
    } | null;
};
export type useCurrentUserQuery = {
    readonly response: useCurrentUserQueryResponse;
    readonly variables: useCurrentUserQueryVariables;
};



/*
query useCurrentUserQuery {
  me {
    ...useCurrentUser_me
    id
    type
    username
    email
    name
    avatarUrl
    projects {
      id
      name
    }
  }
}

fragment useCurrentUser_me on User {
  id
  type
  username
  email
  name
  avatarUrl
  projects {
    id
    name
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatarUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Project",
  "kind": "LinkedField",
  "name": "projects",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useCurrentUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useCurrentUser_me"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useCurrentUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c3b7e62601bd699cd8f2dff51102ee8e",
    "id": null,
    "metadata": {},
    "name": "useCurrentUserQuery",
    "operationKind": "query",
    "text": "query useCurrentUserQuery {\n  me {\n    ...useCurrentUser_me\n    id\n    type\n    username\n    email\n    name\n    avatarUrl\n    projects {\n      id\n      name\n    }\n  }\n}\n\nfragment useCurrentUser_me on User {\n  id\n  type\n  username\n  email\n  name\n  avatarUrl\n  projects {\n    id\n    name\n  }\n}\n"
  }
};
})();
(node as any).hash = '7c16c4f62d336a386c10eb3e8180a99d';
export default node;
